import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import * as LC from 'literallycanvas';
import {Apollo} from 'apollo-angular';
import gql from 'graphql-tag';
import {responseField} from '../../../../store/entities/base.graphql';
import {NotificationService} from "../../../../services/notification.service";


export const SAVE_MY_AGENDA_NOTES_MUTATION = gql`
mutation SaveMyAgendaNotesMutation($input: MyAgendaNotesInputObject) {
  saveMyAgendaNotesMutation(input: $input) {
    response {
      ${responseField}
    }
    data {
      id
      uniqueId
      jsonNotes
    }
  }
}
`;

export const GET_MY_AGENDA_NOTES = gql`
query GetMyAgendaNotes($agendaUniqueId: String) {
  getMyAgendaNotes(agendaUniqueId: $agendaUniqueId) {
    response {
      ${responseField}
    }
    data {
      id
      uniqueId
      jsonNotes
    }
  }
}
`;


@Component({
  selector: 'app-drawing-pad',
  templateUrl: './drawing-pad.component.html',
  styleUrls: ['./drawing-pad.component.scss']
})
export class DrawingPadComponent implements OnInit, OnDestroy, AfterViewInit {

  private apollo: Apollo = inject(Apollo);
  private notificationService: NotificationService = inject(NotificationService);

  @Input({required: true}) agendaUniqueId: string;

  @ViewChild('lcContainer', {static: true}) lcContainer!: ElementRef;
  showOpenFullscreenBtn = true;
  private literallyElement!: HTMLElement;
  private lc: any

  constructor(private renderer: Renderer2) {}


  ngAfterViewInit(): void {
    this.lc = LC.init(this.lcContainer.nativeElement, {
      imageURLPrefix: '/assets/images/lc-images',
      toolbarPosition: 'bottom',
      defaultStrokeWidth: 2,
      backgroundColor: '#F9F9F9',
      strokeWidths: [1, 2, 3, 5, 30]
    });
    this.lc.on('drawingChange', () => {
      console.log(this.lc.getSnapshot());
      this.saveDrawing(JSON.stringify(this.lc.getSnapshot()));
    });
    // this.lc.on('ready', () => {
    //
    //   alert('ready');
    this.getDrawing();
    // });
    this.literallyElement = this.lcContainer.nativeElement.querySelector('.literally');
    document.addEventListener('fullscreenchange', this.onFullscreenChange.bind(this));

  }

  saveDrawing(jsonNotes: string) {
    this.apollo.mutate({
      mutation: SAVE_MY_AGENDA_NOTES_MUTATION,
      variables: {
        input: {
          agendaUniqueId: this.agendaUniqueId,
          jsonNotes
        }
      }
    }).toPromise().then(responseObject => {
      const result: any = Object.values(responseObject)[0]?.saveMyAgendaNotesMutation;

      if (result?.response?.code !== 9000) {
        this.notificationService.errorMessage(result?.response?.message);
      }
    });
  }

  getDrawing() {
    this.apollo.query({
      query: GET_MY_AGENDA_NOTES,
      variables: {
        agendaUniqueId: this.agendaUniqueId,
      }
    }).toPromise().then(responseObject => {
      const result: any = Object.values(responseObject)[0]?.getMyAgendaNotes;

      if (result?.response?.code === 9000) {
        this.lc.loadSnapshot(JSON.parse(result?.data?.jsonNotes));
      } else {
        this.notificationService.errorMessage(result?.response?.message);
      }
    });
  }

  private onFullscreenChange(): void {
    if (!document.fullscreenElement && this.literallyElement) {
      this.literallyElement.style.setProperty('height', '400px');
    }
  }

  toggleFullScreen() {
    const elem = this.lcContainer.nativeElement;

    if (this.showOpenFullscreenBtn) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullscreen();
        elem.webkitRequestFullScreen(elem.ALLOW_KEYBOARD_INPUT);
      }
      const literallyElement = this.lcContainer.nativeElement.querySelector('.literally');
      if (literallyElement) {
        literallyElement.style.setProperty('height', '100vh', 'important');
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }

    this.showOpenFullscreenBtn = !this.showOpenFullscreenBtn;

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    document.removeEventListener('fullscreenchange', this.onFullscreenChange.bind(this));
  }

}
