import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {
  addOfflineAgendaPaper,
  deleteMeetingAgenda,
  editMeetingAgenda,
  exportMeetingActionExtract,
  exportMeetingAgenda,
  exportMeetingMinutes,
  getAllMeetingAgendas,
  getMeetingsAgendas,
  getMinutesByPaper,
  loadMeetingAgendas, reArrangeMeetingAgenda,
  removeMeetingAgenda,
  removeMeetingAgendaAttachment,
  saveAgendaSignedMinutes,
  saveMeetingAgenda,
  setAgendaTime,
  upsertMeetingAgenda
} from './meeting-agenda.actions';
import {SettingsService} from '../../../../services/settings.service';
import {
  DELETE_AGENDA_ATTACHMENT,
  DELETE_MEETING_AGENDA,
  GET_MEETING_AGENDA_BY_MEETING,
  GET_MEETING_AGENDAS,
  GET_MINUTES_BY_PAPER,
  PRINT_MEETING_AGENDA_SUMMARY,
  PRINT_MEETING_MINISTRY_EXTRACT_SUMMARY,
  PRINT_MEETING_MINUTES_SUMMARY, REARRANGE_AGENDA_SEQUENCE,
  SAVE_MEETING_AGENDA,
  SET_AGENDA_TIME,
  UPDATE_MEETING_AGENDA,
  UPLOAD_SIGNED_MEETING_MINUTES
} from './meeting-agenda.graphql';
import {Apollo} from 'apollo-angular';
import {lastValueFrom, of} from 'rxjs';
import {ViewAttachmentComponent} from '../../../../shared/components/views/view-attachment/view-attachment.component';
import {IndexedDBTableName} from "../../../../shared/interfaces/table.interface";
import {NgxIndexedDBService} from "ngx-indexed-db";
import {MeetingAgenda, OfflineAgendaPaper} from "./meeting-agenda.model";
import {getAttachmentsByUid} from "../../settings/attachment/attachment.actions";
import {GET_ATTACHMENT_CONTENT} from "../../settings/attachment/attachment.graphql";

@Injectable()
export class MeetingAgendaEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllMeetingAgendas),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_MEETING_AGENDAS,
        variables: { filtering: action.filtering }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadMeetingAgendas({ meetingAgendas: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });

  exportMinutes = createEffect(() => this.actions$.pipe(
    ofType(exportMeetingMinutes),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: PRINT_MEETING_MINUTES_SUMMARY,
        variables: { meetingUniqueId: action.meetingUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          this.settingsService.openModal(
            {
              base64$: of(result?.data?.base64),
              fileTitle: 'Meeting Minutes',
              filePath: 'meeting-minutes-' + action.meetingUniqueId
            },
            ViewAttachmentComponent, '85%');
        })
      );
    })
  ), { dispatch: false });

  exportAgenda = createEffect(() => this.actions$.pipe(
    ofType(exportMeetingAgenda),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: PRINT_MEETING_AGENDA_SUMMARY,
        variables: { meetingUniqueId: action.meetingUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          this.settingsService.openModal(
            {
              base64$: of(result?.data?.base64),
              fileTitle: 'Meeting Agenda',
              filePath: 'offline-meeting-agenda-' + action.meetingUniqueId
            },
            ViewAttachmentComponent, '85%');
        })
      );
    })
  ), { dispatch: false });

  exportActionExtract = createEffect(() => this.actions$.pipe(
    ofType(exportMeetingActionExtract),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: PRINT_MEETING_MINISTRY_EXTRACT_SUMMARY,
        variables: { input: { meetingUniqueId: action.meetingUniqueId, agendaUniqueId: action?.agendaUniqueId, institutionUniqueId: action?.institutionUniqueId } }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          this.settingsService.openModal(
            {
              base64$: of(result?.data?.base64),
              fileTitle: 'Meeting Action Extract',
              filePath: 'meeting-action-extract-' + action.meetingUniqueId
            },
            ViewAttachmentComponent, '85%');
        })
      );
    })
  ), { dispatch: false });

  getAgendaByMeeting = createEffect(() => this.actions$.pipe(
    ofType(getMeetingsAgendas),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_MEETING_AGENDA_BY_MEETING,
        variables: { meetingUniqueId: action.meetingUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            if (action?.isOffline) {
              lastValueFrom(this.dbService.clear(IndexedDBTableName.active_meeting_agenda)).then(() => {
                lastValueFrom(this.dbService.bulkAdd(IndexedDBTableName.active_meeting_agenda, result?.data?.map(d => {
                  return {
                    data: JSON.stringify(d)
                  };
                }))).then();
              });
            }
            this.store.dispatch(loadMeetingAgendas({ meetingAgendas: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });

  getAttachments(data: OfflineAgendaPaper[]) {
    this.apollo.query({
      query: GET_ATTACHMENT_CONTENT,
      variables: {
        uid: data[0]?.attachmentPath
      }
    }).toPromise().then(responseObject => {
      const result: any = Object.values(responseObject)[0]?.getOpenFiles;
      console.log(result?.data?.base64String);

      if (result?.response?.code === 9000) {
        lastValueFrom(this.dbService.add(IndexedDBTableName.active_meeting_agenda_paper, {
          agenda: data[0]?.agendaUniqueId,
          data: JSON.stringify({
            ...data[0],
            attachmentBase64: result?.data?.base64String
          } as OfflineAgendaPaper)
        })).then(() => {
          this.store.dispatch(addOfflineAgendaPaper());
        });
        data.splice(0, 1);
        if (data?.length) { this.getAttachments(data); }
      }
    });
  }

  getMinutesByPaper$ = createEffect(() => this.actions$.pipe(
    ofType(getMinutesByPaper),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_MINUTES_BY_PAPER,
        variables: { paperUniqueId: action.paperUniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadMeetingAgendas({ meetingAgendas: result?.data }));
          }
        })
      );
    })
  ), { dispatch: false });

  save = createEffect(() => this.actions$.pipe(
    ofType(saveMeetingAgenda),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_MEETING_AGENDA,
        variables: { agenda: action.agenda }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertMeetingAgenda({ meetingAgenda: result?.data }));
            window.location.reload();
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  reArrangeAgenda = createEffect(() => this.actions$.pipe(
    ofType(reArrangeMeetingAgenda),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: REARRANGE_AGENDA_SEQUENCE,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertMeetingAgenda({ meetingAgenda: result?.data }));
            window.location.reload();
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  saveAgendaSignedMinutes = createEffect(() => this.actions$.pipe(
    ofType(saveAgendaSignedMinutes),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPLOAD_SIGNED_MEETING_MINUTES,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertMeetingAgenda({ meetingAgenda: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  edit = createEffect(() => this.actions$.pipe(
    ofType(editMeetingAgenda),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: UPDATE_MEETING_AGENDA,
        variables: { agenda: action.agenda }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertMeetingAgenda({ meetingAgenda: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  setAgendaTime = createEffect(() => this.actions$.pipe(
    ofType(setAgendaTime),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SET_AGENDA_TIME,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);

            this.store.dispatch(upsertMeetingAgenda({ meetingAgenda: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  removeAgendaAttachment$ = createEffect(() => this.actions$.pipe(
    ofType(removeMeetingAgendaAttachment),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_AGENDA_ATTACHMENT,
        variables: { uniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessage(result?.response?.message);

            this.store.dispatch(upsertMeetingAgenda({ meetingAgenda: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });

  delete = createEffect(() => this.actions$.pipe(
    ofType(removeMeetingAgenda),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: DELETE_MEETING_AGENDA,
        variables: { uniqueId: action.uniqueId }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(deleteMeetingAgenda({ id: action?.id }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
    private dbService: NgxIndexedDBService,
  ) { }

}
