import {Component, HostListener, inject, OnInit} from '@angular/core';
import {AuthService} from './services/auth.service';
import {StorageService} from './services/storage-service.service';
import {SpinnerDottedComponent} from './shared/components/views/spinner/spinner-dotted.component';
import {TranslateService} from '@ngx-translate/core';
import {getAllTranslations} from './store/entities/settings/translation/translation.actions';
import {Store} from '@ngrx/store';
import {AppState} from './store/entities';
import {SettingsService} from './services/settings.service';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {INDEX_DB_CONFIGURATION} from './shared/constants/data.const';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  title = 'eCabinet';
  public spinnerComponent = SpinnerDottedComponent;

  translate = inject(TranslateService);
  settingsService = inject(SettingsService);
  storageService = inject(StorageService);
  auth = inject(AuthService);
  private dbService: NgxIndexedDBService = inject(NgxIndexedDBService);

  constructor(
    public store: Store<AppState>,
  ) {

    this.translate.addLangs(['en', 'sw']);

    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'sw');
      this.translate.setDefaultLang('sw');
      this.translate.use('sw');
    } else {
      this.translate.use(localStorage.getItem('lang'));
      this.translate.setDefaultLang(localStorage.getItem('lang'));
    }

    this.initTranslationAndRole().then();
  }

  ngOnInit(): void {
    for (const storeMeta of INDEX_DB_CONFIGURATION.objectStoresMeta?.filter(o => o.store?.includes('active') || o.store?.includes('table_column'))) {
      this.dbService.createObjectStore(storeMeta);
    }

    this.settingsService.loadTranslation();

  }

  async initTranslationAndRole(){
    try {
      if (this.storageService.get('currentClient')){
        await this.auth.authRole();
        this.startSessionWatch();
      }
      this.store.dispatch(getAllTranslations());
    }catch (e){
      this.auth.logout();
    }
  }

  startSessionWatch(){
    if (this.storageService.get('currentClient')) {
      const timeOutId: any = setTimeout(() => this.auth.logout(), this.storageService.get('expiry'));

      if (sessionStorage.getItem('sessionId') !== null){
        clearTimeout(parseInt(sessionStorage.getItem('sessionId'), 10));
      }

      sessionStorage.setItem('sessionId', timeOutId);

    }
  }

  @HostListener('document:mousemove')
  @HostListener('document:click')
  @HostListener('document:keypress')
  resetTimer(): void {
    this.startSessionWatch();
  }

}
