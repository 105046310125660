<div class="fs-container">
  <div class="d-flex justify-content-end mb-2">
    <button (click)="toggleFullScreen()" class="toolbarButton new-tab "
            matTooltip="{{'Open Fullscreen' | concat | translate }}" type="button" color="primary" mat-flat-button>
      <mat-icon class="material-symbols-outlined">fullscreen</mat-icon>
      Fullscreen
    </button>
  </div>
  <div id="lc" #lcContainer></div>
</div>
