import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {NotificationService} from '../../../../services/notification.service';
import {AppState} from '../../index';
import {map, switchMap} from 'rxjs/operators';
import {
  getAllPortalAgendaAnalysisHistory,
  getAllPortalCurrentAgendaAnalysis,
  loadPortalAgendaAnalysiss,
  savePortalAgendaAnalysis, upsertPortalAgendaAnalysis, upsertPortalAgendaAnalysiss
} from './portal-agenda-analysis.actions';
import {SettingsService} from '../../../../services/settings.service';
import {Apollo} from 'apollo-angular';
import {NgxIndexedDBService} from 'ngx-indexed-db';
import {
  GET_MINISTRY_AGENDA_REVIEW,
  GET_MINISTRY_AGENDA_REVIEWS_HISTORY,
  SAVE_MINISTRY_PAPER_REVIEWS_MUTATION
} from './portal-agenda-analysis.graphql';

@Injectable()
export class PortalAgendaAnalysisEffects {

  get = createEffect(() => this.actions$.pipe(
    ofType(getAllPortalAgendaAnalysisHistory),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_MINISTRY_AGENDA_REVIEWS_HISTORY,
        variables: {
          agendaUniqueId: action?.agendaUniqueId
        }
      }).pipe(
        map(({data}: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000) {
            this.store.dispatch(loadPortalAgendaAnalysiss({portalAgendaAnalysiss: result?.data}));
          }
        })
      );
    })
  ), {dispatch: false});

  getCurrent = createEffect(() => this.actions$.pipe(
    ofType(getAllPortalCurrentAgendaAnalysis),
    switchMap((action) => {
      return this.apollo.query({
        query: GET_MINISTRY_AGENDA_REVIEW,
        variables: {
          agendaUniqueId: action?.agendaUniqueId
        }
      }).pipe(
        map(({data}: any) => {
          const result: any = Object.values(data)[0];
          if (result !== null && result?.response?.code === 9000 && result?.data) {
            this.store.dispatch(upsertPortalAgendaAnalysis({portalAgendaAnalysis: result?.data}));
          }
        })
      );
    })
  ), {dispatch: false});

  save = createEffect(() => this.actions$.pipe(
    ofType(savePortalAgendaAnalysis),
    switchMap((action) => {
      return this.apollo.mutate({
        mutation: SAVE_MINISTRY_PAPER_REVIEWS_MUTATION,
        variables: { input: action.input }
      }).pipe(
        map(({ data }: any) => {
          const result: any = Object.values(data)[0];

          if (result !== null && result?.response?.code === 9000) {
            this.notificationService.successMessageAndCloseModals(result?.response?.message);
            this.store.dispatch(upsertPortalAgendaAnalysis({ portalAgendaAnalysis: result?.data }));
          } else {
            this.notificationService.errorMessage(result?.response?.message);
          }
        })
      );
    })
  ), { dispatch: false });


  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationService,
    private settingsService: SettingsService,
    private apollo: Apollo,
    private dbService: NgxIndexedDBService,
  ) {
  }

}
