import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {MaterialModule} from './material/material.module';
import {LoginComponent} from './components/auth/login/login.component';
import {DataTableComponent} from './components/tables/data-table/data-table.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {RouterModule} from '@angular/router';
import {ModalHeaderComponent} from './components/layouts/modal-header/modal-header.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PageTitleComponent} from './components/layouts/page-title/page-title.component';
import {NgxPermissionsAllowStubModule, NgxPermissionsModule, NgxPermissionsRestrictStubModule} from 'ngx-permissions';
import {StrLimitPipe} from './pipes/str-limit/str-limit.pipe';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {AuthLayoutComponent} from './components/auth/auth-layout/auth-layout.component';
import {ViewAttachmentComponent} from './components/views/view-attachment/view-attachment.component';
import {SafeResourcePipe} from './pipes/safe-resource.pipe';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {SearchPipe} from './pipes/search.pipe';
import {ShortNumberPipe} from './pipes/short-number.pipe';
import {MatSelectSearchComponent} from './components/custom-form-fields/mat-select-search/mat-select-search.component';
import {TopRightToolbarComponent} from './components/layouts/top-right-toolbar/top-right-toolbar.component';
import {ChangePasswordFormComponent} from './components/auth/change-password-form/change-password-form.component';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {UserProfileComponent} from './components/views/user-profile/user-profile.component';
import {CustomPdfViewerComponent} from './components/views/custom-pdf-viewer/custom-pdf-viewer.component';
import {ResetPasswordComponent} from './components/auth/reset-password/reset-password.component';
import {SecondsToMinutesPipe} from './pipes/seconds-to-minutes-pipe/seconds-to-minutes';
import {SidebarComponent} from './components/layouts/sidebar/sidebar.component';
import {ModalFixedHeaderComponent} from './components/layouts/modal-fixed-header/modal-fixed-header.component';
import {MainLayoutComponent} from './components/layouts/main-layout/main-layout.component';
import {CountPipe} from './pipes/count.pipe';
import {StrSpacePipe} from './pipes/str-space-pipe/str-space.pipe';
import {SpinnerDottedComponent} from './components/views/spinner/spinner-dotted.component';
import {LandingPageComponent} from './components/layouts/landing-page/landing-page.component';
import {FormatNumberPipe} from './pipes/format-number.pipe';
import {ReplacePipe} from './pipes/replace.pipe';
import {SplitCamelCasePipe} from './pipes/split-camel-case.pipe';
import {IsDateValidPipe} from './pipes/is-date-valid.pipe';
import {NgxPrintModule} from 'ngx-print';
import {ProjectDetailComponent} from './components/views/project-detail/project-detail.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {ViewDocumentReportComponent} from './components/views/view-document-report/view-document-report.component';
import {DocumentDetailComponent} from './components/views/document-detail/document-detail.component';
import {
  ProjectComponentTaskComponent
} from './components/views/project-component-task/project-component-task.component';
import {ProjectReportComponent} from './components/views/project-report/project-report.component';
import {DecisionProgressComponent} from './components/views/decision-progress/decision-progress.component';
import {ObservablePipe} from './pipes/observable.pipe';
import {ProjectFormComponent} from './components/forms/project-form/project-form.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {DirectiveListComponent} from './components/views/directive-list/directive-list.component';
import {DirectiveFormComponent} from './components/forms/directive-form/directive-form.component';
import {Error404Component} from './components/errors/404/error-404.component';
import {Error300Component} from './components/errors/502/error-300.component';
import {
  DirectiveResponseFormComponent
} from './components/forms/directive-response-form/directive-response-form.component';
import {
  DirectiveMovementFormComponent
} from './components/forms/directive-movement-form/directive-movement-form.component';
import {
  ProjectChallengeDetailComponent
} from './components/views/project-challenge-detail/project-challenge-detail.component';
import {
  ProjectRecommendationDetailComponent
} from './components/views/project-recommendation-detail/project-recommendation-detail.component';
import {ProjectProgressListComponent} from './components/views/project-progress-list/project-progress-list.component';
import {SumPipe} from './pipes/sum.pipe';
import {ProjectImagesDetailComponent} from './components/views/project-images-detail/project-images-detail.component';
import {DateSuffixPipe} from './pipes/date-suffix.pipe';
import {
  DateRangeFormFieldComponent
} from './components/custom-form-fields/date-range-form-field/date-range-form-field.component';
import {TranslateModule} from '@ngx-translate/core';
import {ConcatPipe} from './pipes/concat.pipe';
import {CdkTableModule} from '@angular/cdk/table';
import {NgObjectPipesModule} from 'ngx-pipes';
import {PluckPipe} from './pipes/pluck.pipe';
import {TablePageableComponent} from './components/tables/table-pageable/table-pageable.component';
import {
  AttachmentFormFieldComponent
} from './components/custom-form-fields/attachment-form-field/attachment-form-field.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {ViewAttachmentListComponent} from './components/views/view-attachment-list/view-attachment-list.component';
import {
  ProjectRepositoryDetailComponent
} from './components/views/project-repository-detail/project-repository-detail.component';
import {TextEditorFieldComponent} from './components/custom-form-fields/text-editor-field/text-editor-field.component';
import {QuillEditorComponent} from 'ngx-quill';
import {TwoFactorAuthFormComponent} from './components/auth/two-factor-auth-form/two-factor-auth-form.component';
import {TwoFactorAuthLoginComponent} from './components/auth/two-factor-auth-login/two-factor-auth-login.component';
import {
  OfflineOnlineSelectComponent
} from "./components/layouts/landing-page/offline-online-select/offline-online-select.component";
import {DrawingPadComponent} from "./components/views/drawing-pad/drawing-pad.component";

@NgModule({
  declarations: [
    LoginComponent, PageTitleComponent, DataTableComponent, ModalHeaderComponent, UserProfileComponent,
    StrLimitPipe, CountPipe, StrSpacePipe, AuthLayoutComponent, FormatNumberPipe, TablePageableComponent,
    ViewAttachmentComponent, SafeResourcePipe, SidebarComponent, ProjectReportComponent, DocumentDetailComponent,
    SearchPipe, MatSelectSearchComponent, TopRightToolbarComponent, ResetPasswordComponent, TextEditorFieldComponent,
    ChangePasswordFormComponent, SafeHtmlPipe, ShortNumberPipe, ViewDocumentReportComponent,
    CustomPdfViewerComponent, SecondsToMinutesPipe, DecisionProgressComponent, ModalFixedHeaderComponent,
    MainLayoutComponent, SpinnerDottedComponent, ProjectDetailComponent, AttachmentFormFieldComponent,
    LandingPageComponent, ProjectComponentTaskComponent, ProjectImagesDetailComponent, ProjectRepositoryDetailComponent,
    ProjectFormComponent, DirectiveListComponent, DirectiveFormComponent, ViewAttachmentListComponent,
    Error404Component, Error300Component, DirectiveResponseFormComponent, ProjectProgressListComponent,
    DirectiveMovementFormComponent, ProjectChallengeDetailComponent, ProjectRecommendationDetailComponent, ReplacePipe,
    SumPipe,
    SplitCamelCasePipe,
    IsDateValidPipe,
    ObservablePipe,
    DateSuffixPipe,
    DateRangeFormFieldComponent,
    TwoFactorAuthFormComponent,
    TwoFactorAuthLoginComponent,
    OfflineOnlineSelectComponent,
    DrawingPadComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    RouterModule,
    ReactiveFormsModule,
    NgxExtendedPdfViewerModule,
    FormsModule,
    DragDropModule,
    NgxPermissionsModule.forChild(),
    NgxPermissionsRestrictStubModule,
    NgxPrintModule,
    NgApexchartsModule,
    NgxMatSelectSearchModule,
    TranslateModule,
    ConcatPipe,
    CdkTableModule,
    NgObjectPipesModule,
    PluckPipe,
    NgxDropzoneModule,
    QuillEditorComponent,
    NgOptimizedImage
  ],
  exports: [
    NgxPermissionsModule,
    NgxPermissionsAllowStubModule,
    MaterialModule,
    DataTableComponent,
    ModalHeaderComponent,
    PageTitleComponent,
    StrLimitPipe,
    ObservablePipe,
    SearchPipe,
    SumPipe,
    ShortNumberPipe,
    MatSelectSearchComponent,
    SafeHtmlPipe,
    ReactiveFormsModule,
    CustomPdfViewerComponent,
    ModalFixedHeaderComponent,
    MainLayoutComponent,
    CountPipe,
    StrSpacePipe,
    TopRightToolbarComponent,
    FormatNumberPipe,
    ReplacePipe,
    SafeResourcePipe,
    DateRangeFormFieldComponent,
    TablePageableComponent,
    AttachmentFormFieldComponent,
    TextEditorFieldComponent,
    TwoFactorAuthFormComponent,
    DrawingPadComponent
  ]
})
export class SharedModule { }
